<template>
    <div>
        <iframe
            :src="getDashboard(appConfig.layout.skin)"
            frameborder="0"
            v-resize="{ log: true }"
            width="100%"
            allowtransparency
        ></iframe>
    </div>
</template>

<script>
import jwt from "jsonwebtoken";
import { mapState } from "vuex";
import iframeResize from "iframe-resizer/js/iframeResizer";

var METABASE_SITE_URL = "https://metabase-intra.estrategia.io";
var METABASE_SECRET_KEY = "66bc8f1e8f481928a5d6c131bd2de864adee8b91c4a0c3c62a436b695b6322f0";

export default {
    computed: {
        ...mapState(["appConfig"]),
    },
    data() {
        return {
            payload: {
                resource: { dashboard: 23 },
                params: {},
                exp: Math.round(Date.now() / 1000) + 10 * 60, // 10 minute expiration
            },
        };
    },
    directives: {
        resize: {
        bind: function (el, { value = {} }) {
            el.addEventListener("load", () => iframeResize(value, el));
        },
        unbind: function (el) {
            el.iFrameResizer.removeListeners();
        },
        },
    },
    methods: {
        getDashboard(skin) {
        var token = jwt.sign(this.payload, METABASE_SECRET_KEY);
        var theme = skin === "dark" ? "&theme=night" : "";
        var iframeUrl =
            METABASE_SITE_URL +
            "/embed/dashboard/" +
            token +
            "#bordered=false&titled=false&refresh=60" +
            theme;

        return iframeUrl;
        }
    },
};
</script>